import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Wrapper
import Private from "./authentication/Private";
import Public from "./authentication/Public";
// Wrapper


// screens
import Login from "./screens/login";
import UploadHistory from "./screens/upload-history";
import FileHistory from "./screens/file-history";
import ClaimHistory from "./screens/claims-history";
import FileContent from "./screens/file-content";
import Era from "./screens/era";
// screens



function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="/login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="/upload-history"
            element={
              <Private>
                <UploadHistory />
              </Private>
            }
          />
          <Route
            path="/file-history/:id"
            element={
              <Private>
                <FileHistory />
              </Private>
            }
          />
          <Route
            path="/claim-history/:id"
            element={
              <Private>
                <ClaimHistory />
              </Private>
            }
          />
          <Route
            path="/file-content/:id"
            element={
              <Private>
                <FileContent />
              </Private>
            }
          />
          <Route
            path="/era"
            element={
              <Private>
                <Era />
              </Private>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
