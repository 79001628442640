import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getAccessToken } from "../../utils/Token";

const Era = () => {

  let storeData = []
  let { id } = useParams();
  const [eraData, setEraData] = useState([]);
  const [formData, setFormData] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredEraData, setFilteredEraData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const wrapper = document.querySelector(".content-wrapper");
    const innerWrapper = document.querySelector(".content-wrapper-inner");
    wrapper.classList.add("vh-100")
    innerWrapper.classList.add("h-100")
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const endDate = now;
    getDataByApi(formatDate(startDate), formatDate(endDate), null, null);
  }, [id]);

  useEffect(() => {
    const sum = eraData.reduce((acc, item) => acc + parseFloat(item.checkAmount) || 0, 0);
    setTotalAmount(sum);
  }, [eraData]);

  const getDataByApi = async (startDate, endDate, companyName, checkNumber) => {
    try {
      setLoading(true);
      // startDate, endDate, companyName, checkNumber
      const response = await axios.post(
        `https://emrtest.genensys.com/clearinghouse/getRemitAdvices?startDate=${startDate}&endDate=${endDate}&companyName=${companyName}&checkNumber=${checkNumber}`,
        {}, // Pass request body here (empty object if no body is required)
        {
          headers: {
            'Authorization': getAccessToken(),
            'Content-Type': 'application/json', // Optional, add if the server expects JSON
          },
        }
      );
      if (response?.data?.data.length > 0) {
        storeData = response?.data?.data;
        setEraData(response?.data?.data);
        setFilteredEraData(response?.data?.data);
      } else {
        storeData = [];
        setEraData([]);
        setFilteredEraData([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // const handleSearch = async (e) => {
  //   const value = e.target.value;
  //   const data = eraData;
  //   if (value.length > 0) {
  //     const filterData = data.filter(item => item.companyName.toLowerCase().includes(e?.target?.value) || item.checkNumber.toLowerCase().includes(e?.target?.value));
  //     setFormData({ ...formData, search: e.target.value });
  //     setFilteredEraData(filterData);
  //   } else {
  //     setFilteredEraData(eraData);
  //   }
  // }

  const handleDateFilter = () => {
    if (formData.startDate != "" && formData.startDate != null && formData.startDate != undefined && formData.endDate != "" && formData.endDate != null && formData.endDate != undefined) {
      getDataByApi(formData.startDate, formData.endDate, null, null);
    }
  }

  const handleSearch = () => {
    if (isOnlyNumber(formData.search)) {
      getDataByApi(null, null, null, formData.search);
    } else {
      getDataByApi(null, null, formData.search, null);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (isOnlyNumber(formData.search)) {
        getDataByApi(null, null, null, formData.search);
      } else {
        getDataByApi(null, null, formData.search, null);
      }
    }
  }

  const isOnlyNumber = (value) => {
    return /^[0-9]+$/.test(value); // Ensures only number format
  }

  const formatDate = (date) => {
    return date.toISOString().split("T")[0]; // Ensures YYYY-MM-DD format
  };

  return (
    <div className="d-flex flex-column h-100">
      <h1 className="mb-4">ERAs History</h1>

      <div className="mb-4 d-flex justify-content-between">
        <div className="d-flex">
          <div className="form-field flex-shrink-0 me-4">
            <label className="fw-bold mb-2">Start Date</label>
            <input type="date" className="form-control" defaultValue="" name="startDate" onChange={(e) => setFormData({ ...formData, startDate: e.target.value })} />
          </div>
          <div className="form-field flex-shrink-0 me-4">
            <label className="fw-bold mb-2">End Date</label>
            <input type="date" className="form-control" defaultValue="" name="endDate" onChange={(e) => setFormData({ ...formData, endDate: e.target.value })} />
          </div>
          <div className="form-field flex-shrink-0 align-self-end">
            <button disabled={(formData.startDate === "" && formData.endDate === "") ? true : false} onClick={handleDateFilter} className="btn btn-primary">Submit</button>
          </div>
        </div>
        <div className="form-field flex-shrink-0 w-100" style={{ maxWidth: 300 }}>
          <label className="fw-bold mb-2">Search</label>
          <div className="position-relative">
            <input type="text" className="form-control pe-5 mw-100" defaultValue="" name="search" onKeyDown={handleKeyDown} onChange={(e) => setFormData({ ...formData, search: e.target.value })} />
            <span type="button" className="position-absolute top-50 right-0 translate-middle-y me-3" onClick={handleSearch} >
              <i className="fal fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>File Id</th>
              <th>File Name</th>
              <th>Company Name</th>
              <th>Check No</th>
              <th>Check Date</th>
              <th>Check Amount</th>
            </tr>
          </thead>
          <tbody>
            {(!loading && eraData.length > 0) && (
              <>
                {eraData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.fileId}</td>
                    <td>{item.fileName}</td>
                    <td>{item.companyName}</td>
                    <td>{item.checkNumber}</td>
                    <td>{item.checkDate}</td>
                    <td>{item.checkAmount}</td>
                  </tr>
                ))}
                {/* Display the total at the end */}
                <tr>
                  <td colSpan="5"><strong>Total Amount:</strong></td>
                  <td><strong>{totalAmount.toFixed(2)}</strong></td>
                </tr>
              </>
            )}
            {
              loading && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )
            }
            {
              (!loading && eraData.length === 0) && (
                <tr>
                  <td colSpan={6} className="text-center">No Record Found</td>
                </tr>
              )
            }
          </tbody>
          {/* <tfoot style={{ position: "sticky", bottom: "0", background: "#fff", boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)" }}>
                <tr>
                  <td colSpan="5" style={{ fontWeight: "bold" }}>Total:</td>
                  <td style={{ fontWeight: "bold" }}>{totalAmount.toFixed(2)}</td>
                </tr>
              </tfoot> */}
        </table>
      </div>
      {/* <div className="position-relative flex-grow-1">
        <div className="position-absolute top-0 start-0 h-100 w-100 overflow-auto">

        </div>
      </div> */}
    </div>
  );
};

export default Era;
