import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// images
import logo from "../assets/img/logo.png";

const Sidebar = () => {

  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    if (window?.location?.pathname === "/upload-history") {
      setDropDown(true);
    }
  }, [])


  return (
    <div className="sidebar-content-wrapper">
      <ul className="navigation">
        <li className="logo" data-item="">
          <img src={logo} alt="" />
        </li>
        <hr className="divider m-b-0" />
        <li className="nav-item dropdown" data-item="dashboard">
          <div className="item" onClick={() => setDropDown(!dropDown)}>
            <span>
              <i className="fal fa-book-medical"></i>
              <p className="mb-0 text-sm">Claims</p>
            </span>
            <i className={`fas ${dropDown ? 'fa-chevron-down' : 'fa-chevron-right'} drop-icon`}></i>
          </div>
          {dropDown && (
            <div className="drop-items">
              <h6>Patients Claims:</h6>
              <NavLink to="/" className="drop-item">
                EDI Reports
              </NavLink>
              <NavLink to="/upload-history" className="drop-item">
                Upload History
              </NavLink>
              <NavLink to="/" className={`${window.location.pathname === '/file-history' && 'active'} drop-item`}>
                File History
              </NavLink>
              <NavLink to="/" className={`${window.location.pathname === '/claim-history' && 'active'} drop-item`}>
                Claims History
              </NavLink>
            </div>
          )}
        </li>
        <li className="nav-item" data-item="eras">
          <div className="item">
            <NavLink to="/era" className={`${window.location.pathname === '/era' && 'active'} drop-item`}>
              <span>
                <i className="fal fa-file-medical-alt"></i>
                <p className="mb-0 text-sm">ERAs</p>
              </span>
            </NavLink>
          </div>
        </li>
        <li className="nav-item" data-item="eligibility">
          <div className="item">
            <span>
              <i className="fal fa-books-medical"></i>
              <p className="mb-0 text-sm">Eligibility</p>
            </span>
          </div>
        </li>
        <li className="nav-item" data-item="eligibility">
          <div className="item">
            <span>
              <i className="fa fa-file-medical-alt"></i>
              <p className="mb-0 text-sm">Payment</p>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
